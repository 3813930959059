const getJSON = async url => {
   console.log(`URL: ${url}`);
   try {
      // credentials: 'include' is used to send the cookie to the server
      const fetchData = await fetch(url, {
         credentials: 'include',
      });

      const res = await fetchData.json();

      return res;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

const postJSON = async (url, data) => {
   try {
      const postData = await fetch(url, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(data),
         credentials: 'include',
      });

      const res = await postData.json();

      return res;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

const postJSONMultiform = async (url, data) => {
   try {
      const postData = await fetch(url, {
         method: 'POST',
         credentials: 'include',
         body: data,
      });

      const res = await postData.json();

      return res;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

const patchJSON = async (url, data) => {
   try {
      const updateData = await fetch(url, {
         method: 'PATCH',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(data),
         credentials: 'include',
      });

      const res = await updateData.json();

      return res;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

const patchJSONMultiform = async (url, data) => {
   try {
      const updateData = await fetch(url, {
         method: 'PATCH',
         body: data,
         credentials: 'include',
      });

      const res = await updateData.json();

      return res;
   } catch (err) {
      console.error(err);
      throw err;
   }
};

const deleteJSON = async url => {
   try {
      const deleteData = await fetch(url, {
         method: 'DELETE',
         credentials: 'include',
      });

      const res = deleteData.status;

      return res;
   } catch (err) {
      console.error(err.message);
      throw err;
   }
};

export {
   getJSON,
   postJSON,
   postJSONMultiform,
   patchJSON,
   patchJSONMultiform,
   deleteJSON,
};
