class BurgerView {
   #burgerMenu;
   #burgerMenuIcon;
   #exitMenuIcon;
   #blurryOverlay;

   init() {
      this.#setVariables();
      this.#showEventListeners();
      this.#closeEventListeners();
   }

   #setVariables() {
      // Header nav is for public pages and burger-wrapper is for in app
      this.#burgerMenu =
         document.querySelector('header nav') ||
         document.querySelector('.burger-wrapper');
      this.#burgerMenuIcon = document.querySelector('.burger-icon');
      this.#exitMenuIcon = document.querySelector('.exit-icon');
      this.#blurryOverlay = document.querySelector('.blurry-overlay');
   }

   #showEventListeners() {
      this.#burgerMenuIcon.addEventListener('click', this.#show.bind(this));
   }

   #closeEventListeners() {
      this.#blurryOverlay.addEventListener('click', this.close.bind(this));
      this.#exitMenuIcon.addEventListener('click', this.close.bind(this));
      window.addEventListener('keydown', e => {
         if (e.key === 'Escape') this.close();
      });
   }

   #show() {
      if (this.#burgerMenu.classList.contains('active')) return;

      this.#burgerMenu.classList.add('active');
      this.#blurryOverlay.classList.add('active');
   }

   close() {
      console.log(this.#burgerMenu);
      if (!this.#burgerMenu.classList.contains('active')) return;

      this.#burgerMenu.classList.remove('active');
      this.#blurryOverlay.classList.remove('active');
   }
}

export default new BurgerView();
