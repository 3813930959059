import userIsLoggedIn from './userIsLoggedIn';

const updateUserLocalStorage = user => {
   const {
      firstName,
      lastName,
      email,
      imageUrl,
      experience,
      cardsStudied,
      streak,
      id,
      createdVia,
      subscription,
      usage,
   } = user.data.data;

   localStorage.setItem('firstName', firstName);
   localStorage.setItem('lastName', lastName);
   localStorage.setItem('email', email);
   localStorage.setItem('imageUrl', imageUrl);
   localStorage.setItem('experience', experience);
   localStorage.setItem('cardsStudied', cardsStudied);
   localStorage.setItem('streak', streak);
   localStorage.setItem('id', id);
   localStorage.setItem('createdVia', createdVia);
   localStorage.setItem('subscription', JSON.stringify(subscription));
   localStorage.setItem('usage', JSON.stringify(usage));
};

export default updateUserLocalStorage;
