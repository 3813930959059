import BurgerView from '../view/BurgerView';

class BurgerController {
   init() {
      console.log(`init`);
      BurgerView.init();
   }

   close() {
      console.log(`close`);
      BurgerView.close();
   }
}

export default new BurgerController();
