import { getJSON } from './requests';
import { API_URL } from '../config';
import { sendMessageToNextPage, showMessage } from './messages';
import updateUserLocalStorage from './updateUserLocalStorage';

// Checks if the user is logged in
// @param {boolean} publicPage - Indicates if the current page is a public page (e.g., homepage, login, signup) where the user does not need to be logged in. If true, the function will not throw an error for non-200 status codes
const userIsLoggedIn = async (publicPage = false) => {
   try {
      // Get the user's data from the server
      const user = await getJSON(`${API_URL}/users/me`);

      // If it's not a public page (user needs to be logged in) and the status code is not 200, throw an error
      if (!publicPage && user.statusCode !== 200)
         window.location.href = '/login.html';

      // If the user is logged in
      if (user.statusCode === 200) {
         // Store the user's data in local storage
         updateUserLocalStorage(user);
         return true;
      }

      // If the user is not logged in
      return false;
   } catch (err) {
      console.error(err);
      showMessage('negative', err.message);
   }
};

export default userIsLoggedIn;
