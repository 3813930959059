import dotenv from 'dotenv';
dotenv.config();

const config = {
   development: {
      ROOT_URL: 'http://127.0.0.1:3000',
      ROOT_URL_FRONTEND: 'http://localhost:1234',
   },
   production: {
      ROOT_URL: process.env.ROOT_URL,
      ROOT_URL_FRONTEND: process.env.ROOT_URL_FRONTEND,
   },
};

const nodeEnv = process.env.NODE_ENV;

// Extract URLs based on the current environment
// config['development'] or config['production']
const { ROOT_URL, ROOT_URL_FRONTEND } = config[nodeEnv];

const API_URL = `${ROOT_URL}/api/v1`;

console.log(ROOT_URL, ROOT_URL_FRONTEND, API_URL);

export { ROOT_URL, API_URL, ROOT_URL_FRONTEND };
