// Color can be either white or blue
const renderSpinner = (element, color) => {
   const html = `<div class="spinner ${color}"></div>`;

   element.insertAdjacentHTML('afterbegin', html);
};

const removeSpinner = element => {
   const spinner = element.querySelector('.spinner');

   spinner.remove();
};

export { renderSpinner, removeSpinner };
